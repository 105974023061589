import React from "react";
import Layout from "../../layouts/index";
import imgcasestudy2 from '../../images/casestudy2.png';

class casestudy2 extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-aboutUs">
        <div className="intro pt-6 pt-sm-8 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Managing Invoicing and Early Payments in the Telco Industry</h1>
              </div>
            </div>
            <hr className="section-divider mt-sm-7 mb-4 mb-sm-9" />
            <div className="row">
              <div className="col-12">
                <h2 className="title-3 text-primary mb-1">
                  The Current Challenge: :
                </h2>
                <h2 className="title-3 text-primary mb-1">
                  Invoice Management &amp; Early Payment of Sub Contractors:
                </h2>
                <p>
                  The company accepts orders from Leading Australian Telco’s'
                  for the laying of NBN cabling across Australia. Company
                  engages various Sub Contractors (subbies) across Australia to
                  fulfil these orders. Subbie issues invoice on completion of
                  the order, customer pays invoice once it verifies that the
                  work has been completed in accordance with the order.{" "}
                </p>
                <p>
                  Company provides an early payment option for the subbies,
                  whereby a subbie can call the company and request early
                  payment for one or several invoices it has issued. Company
                  would pay the subbie a diluted invoice amount, the same day.
                  This process is handled manually by the company.
                </p>
                <p>
                  The early payment process, had many challenges to both the
                  subbie and the company, some of the challenges being
                </p>
                <ul className="custom-list accent pr-lg-6">
                  <li>
                    <p>
                      Manual process. Company employees had to take phone call
                      requests from various subbies.
                    </p>
                  </li>
                  <li>
                    <p>Not scalable, costly for the customer.</p>
                  </li>
                  <li>
                    <p>
                      Subbie had no assurance of getting through to the customer
                      on a given day
                    </p>
                  </li>
                  <li>
                    <p>
                      Subbie had no assurance of payment, leading to cash flow
                      challenges for the subbie.
                    </p>
                  </li>
                  <li>
                    <p>
                      By law company has to verify and authenticate payment
                      accounts
                    </p>
                  </li>
                  <li>
                    <p>Subbies unaware of the liquidity of the company</p>
                  </li>
                </ul>
                <h2 className="title-3 text-primary mb-1">The Solution</h2>
                <p>
                  Company deploys Lilardia Capital (LC) Reverse Factoring
                  solution. LC portal conducts the necessary regulatory checks
                  such as KYC. Approved invoices are automatically extracted
                  from company accounting system (XERO) and populated onto LC
                  portal, which is branded as the customer. Subbie logs in
                  directly to LC portal, to browse all approved invoices and
                  select the ones that it requires to submit for early payment.
                  LC processes the early payment requests, generates payment
                  instructions, and submits it to the bank. Subbie gets diluted
                  credit funds within a few days. Subbie is notified via email,
                  that the payments been made. LC provides audit trail to the
                  company on the transaction.
                </p>
                <p>
                  Subbie logs into the portal, sees updated dashboard of all
                  approved invoices, invoices that have been paid early and
                  invoices that are to be paid on maturity.{" "}
                </p>
                <h2 className="title-3 text-primary mb-1">The Benefits</h2>
                <p>
                  Subbie gets assurance of payment, enabling subbie to better
                  manage its cash.
                </p>
                <p>
                  Overall subbie experience is enhanced, Subbie can now browse
                  all invoices approved by customer, with payment dates. Subbie
                  does not need to call the company. Customer does not have to
                  field telephone call requests for early payment.
                </p>
                <p>
                  Early payment process is fully automated, making it scalable
                  for the company, without bringing on additional staff
                </p>
                <p>
                  Company can expand the solution for its total payments,
                  thereby utilizing the platform as a treasury platform.
                </p>
                <div className="mt-7">
                  <div className="casst-img"> <img className="img-fluid" className="casst" src={imgcasestudy2} /></div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default casestudy2;
